import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Directive, Injector } from '@angular/core';
import { Minicode, TextTemplateService } from './services/text-template.service';
import { CanDeactivateType } from "./guards/can-deactivate.guard";
import { JourneyLogType } from './enums/journey-log-type';
import { JourneyLogsService } from './services/journey-logs.service';
import { JourneyMilestoneService } from './services/journey-milestone.service';
import { JourneyService } from './services/journey.service';
import { MilestoneStatuses } from './enums/milestone-status';
import { NavigationService } from "./services/navigation.service";
import { Pages } from './enums/page';
import { StepState } from './enums/step-state';
import { TextTemplateField } from './enums/text-template-field';
import { firstValueFrom } from 'rxjs';
import moment from 'moment';

@Directive()
export abstract class ComponentBase {
    constructor(page: Pages, injector: Injector, selector: string) {
        this.navigationService = injector.get(NavigationService);
        this.journeyLogsService = injector.get(JourneyLogsService);
        this.journeyService = injector.get(JourneyService);
        this.textTemplateService = injector.get(TextTemplateService);
        this.journeyMilestoneService = injector.get(JourneyMilestoneService);
        this.arrived = moment().utc().toDate();
        this.page = page;
        this.navigationService.setActivePage(page);
        this.selector = selector;
    }

    readonly navigationService: NavigationService;
    readonly journeyLogsService: JourneyLogsService;
    readonly journeyService: JourneyService;
    readonly textTemplateService: TextTemplateService;
    readonly journeyMilestoneService: JourneyMilestoneService;
    private arrived: Date;
    private page?: Pages;
    selector: string;
    textTemplateFieldEnum = TextTemplateField;
    @BlockUI() blockUI!: NgBlockUI;

    async canDeactivate(): Promise<CanDeactivateType> {
        return await this.save();
    }

    async save(): Promise<boolean> {
        try {
            this.blockUI.start();
            await this.updateStep();

            await firstValueFrom(this.journeyLogsService.saveJourneyLog([
                this.journeyLogsService.createJourneyLog(
                    JourneyLogType.PageVisit,
                    this.journeyService.getNonNullableJourney().journeyID,
                    void null,
                    this.page,
                    this.arrived,
                    moment().utc().toDate(),
                    this.journeyService.getNonNullableJourney().journeyInstanceID
                )]));

            return true;
        } finally {
            this.blockUI.stop();
        }
    }

    async navigateForward(): Promise<boolean> {
        return await this.navigationService.navigateForward();
    }

    async navigateBack(): Promise<boolean> {
        return await this.navigationService.navigateBack();
    }

    getTextTemplateSectionText(textTemplateField: TextTemplateField, minicodes?: Minicode[]): string {
        switch (textTemplateField) {
            case TextTemplateField.Title:
                return this.textTemplateService.getSectionTitle(this.selector, minicodes);

            case TextTemplateField.Info:
                return this.textTemplateService.getSectionInfo(this.selector, minicodes);
            default:
                return TextTemplateService.placeholder;
        }
    }

    getTextTemplateElementText(textTemplateField: TextTemplateField, elementIdentifier: string, minicodes?: Minicode[]): string {
        switch (textTemplateField) {
            case TextTemplateField.Text:
                return this.textTemplateService.getSectionElementText(this.selector, elementIdentifier, minicodes);

            case TextTemplateField.SubText:
                return this.textTemplateService.getSectionElementSubText(this.selector, elementIdentifier, minicodes);
            default:
                return TextTemplateService.placeholder;
        }
    }

    private async updateStep(): Promise<void> {
        const step = this.navigationService.currentStep.getValue();
        
        if (step.parentStepID) {
            step.stepState = StepState.Completed;
        } else {
            step.stepState = (this.navigationService.steps.some(x => x.stepState !== StepState.Completed && x.parentStepID === step.page)) ? StepState.Visited : StepState.Completed;
        }

        const parentStep = this.navigationService.steps.find(x => x.page === step.parentStepID);

        if (parentStep) {
            parentStep.stepState = (this.navigationService.steps.some(x => x.stepState !== StepState.Completed && x.parentStepID === step.parentStepID)) ? StepState.Visited : StepState.Completed;

            if (step.lastStep) {
                parentStep.rightCompleted = true;

                const nextParent = this.navigationService.steps.find(x => x.identifier === step.nextStepIdentifier);

                if (nextParent) nextParent.leftCompleted = true;
            }
        }

        await this.journeyMilestoneService.updateJourneyMilestone(step.page, MilestoneStatuses.Completed);
    }
}
